<template>
  <div class="home" :style="homeStyle">
    <router-view></router-view>
    <component v-for="(item, index) in homeData" :key="index" :is="item.type" :comObj="item.data"></component>
    <!-- <DiyTabar :comObj="tabbar" v-if="Object.keys(tabbar).length != 0"></DiyTabar> -->
  </div>
</template>

<script>
import axios from "axios";
import testjson from "./test.json"
export default {
  components: {
    DiyImg: () => import("./components/DiyImg"),
    DiyList: () => import("./components/DiyList"),
    DiyNav: () => import("./components/DiyNav"),
    DiySearch: () => import("./components/DiySearch"),
    DiySwiper: () => import("./components/DiySwiper"),
    DiyTabar: () => import("./components/DiyTabar"),
    DiyAdvert: () => import("./components/DiyAdvert"),
    DiyBD: ()=> import("./components/DiyBD"),
    DiyActivity: () => import("./components/DiyActivity"),
    DiyMarket: () => import("./components/Diymarket")
  },
  data() {
    return {
      testjson: testjson.page_json,
      homeData: {},
      homeStyle: {
        background: "",
        opacity: 1
      },
      tabbar: {},
      group_page: {
        group_idx: -1,
        page_idx: -1
      }
    }
  },
  watch: {
    group_page: {
      handler(newVal) {
        this.getPageData(newVal.group_idx, newVal.page_idx)
      },
      deep: true
    }
  },
  methods: {
    getPageData(group_idx, page_idx) {
      var appid = sessionStorage.getItem("app_id");
      axios({
        method: 'post',
        url:"https://www.58zltc.com/api/page/group_list",
        header: "application/x-www-form-urlencoded",
        data: {
          app_id: appid
        }
      }).then(res => {
        this.homeData = JSON.parse(res.data.data[group_idx].page_list[page_idx].page_json);
        this.homeStyle = JSON.parse(res.data.data[group_idx].page_list[page_idx].background);
        this.tabbar = JSON.parse(res.data.data[group_idx].page_list[page_idx].tabbar);
      })
    },
  },
  mounted() {
    this.group_page.page_idx = this.$route.query.page_idx;
    this.group_page.group_idx = this.$route.query.group_idx;
    console.log(testjson)
  }
}
</script>

<style lang="scss" scoped>
  .home {
    padding-bottom: 50px;
    padding-top: 45px;
  }
</style>
